
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component({
  components: {
    LogoComponent: () => import(
      /* webpackChunkName: "LogoComponent" */
      '@/components/Logo.component.vue'
    ),
  }
})
export default class AppView extends Vue {
}
